import React from 'react'

function Alert(props) {
    const capitalize = (word)=>{
      if(word === "danger"){
        word = "error";
      }
        const lower = word.toLowerCase();
        return lower.charAt(0).toUpperCase() + lower.slice(1);
    }
    return (
         props.alert && <div className={`alert alert-${props.alert.type} alert-dismissible fade show`} role="alert">
            <strong>{capitalize(props.alert.type)} </strong> {props.alert.msg}
            
        </div>
    )
}

export default Alert

// import React from "react";

// const Alert = (props) => {
//   return (
//     <div>
//       <div className="alert alert-primary" role="alert">
//         {props.message}
//       </div>
//     </div>
//   );
// };

// export default Alert;
