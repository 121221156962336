//import react from "react";
// import { useState } from "react/cjs/react.production.min";
import NoteContext from "./noteContext";
import { useState } from "react"

const NoteState = (props)=>{
    const host = "http://localhost:5000";
    const notesInitial = [];
      const [notes, setNotes] = useState(notesInitial);
    // const s1 = {
    //     "name":"Binay",
    //     "class": "5A"
    // }
    // const [state, setstate] = useState(s1);
    // const update = ()=>{
    //     setTimeout(() => {
    //         setstate({
    //             "name":"Kumar",
    //             "class": "10A"
    //         })
    //     }, 1000);
    // }

    // Get a Note
    const getNotes = async ()=>{
      // API CALL
      const response = await fetch(`${host}/api/notes/fetchallnotes`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "auth-token": localStorage.getItem('token')
        }
      });
      const json = await response.json();
      console.log(json)
      setNotes(json)
    }


    // Add a Note
      const addNote = async (title, description, tag)=>{
        // TODO : API CALL.
        // API CALL
        const response = await fetch(`${host}/api/notes/addnote`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            "auth-token": localStorage.getItem('token')
          },
          body: JSON.stringify({title, description, tag}) 
        });
         //Logic to add note.
        const note = await response.json();
        if(notes.length === 0){
          setNotes(notes.concat(note))
         
        }else{
          setNotes(note)
        }      
      }
    // Delete a Note
    const deleteNote = async (id)=>{
       //  API CALL
       const response = await fetch(`${host}/api/notes/deletenote/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          "auth-token": localStorage.getItem('token')
        }
      });
      const json = await response.json();
      console.log(json);

        const newNotes = notes.filter((note)=>{return note._id !== id});
        setNotes(newNotes);
    }
    // Edit a Note
    const editNote = async (id, title, description, tag)=>{
      // API call
      const response = await fetch(`${host}/api/notes/updatenote/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          "auth-token": localStorage.getItem('token')
        },
        body: JSON.stringify({title, description, tag})
      });
      const json = await response.json();
      console.log(json);
      let newNotes = JSON.parse(JSON.stringify(notes)); //A deep copy of the notes is created in order to update instamtly on frontend.
      //logic to edit in client.
        for (let index = 0; index < notes.length; index++) {
          const element = newNotes[index];
          if(element._id === id){
            newNotes[index].title = title;
            newNotes[index].description = description;
            newNotes[index].tag = tag;
            break;
          }
          
        }
        setNotes(newNotes);
    }
    return (
        //<NoteContext.Provider value={{state, update}}>
        <NoteContext.Provider value={{ notes, addNote, deleteNote, editNote, getNotes }}>
            {props.children}
        </NoteContext.Provider>
    )
}

export default NoteState;