// import React, { useContext, useEffect } from 'react'
import React from 'react'
// import noteContext from '../context/notes/noteContext'

const About = () => {
    // const a = useContext(noteContext);
    // useEffect(() => {
    //    a.update();
       
    // // eslint-disable-next-line 
    // }, [])
    return (
        <div>
            {/* This is About {a.name} And he is in class {a.class}...... */}
            This is About page......
        </div>
    )
}

export default About
