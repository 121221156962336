import React, {useContext, useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import noteContext from "../context/notes/noteContext";
import AddNote from "./AddNote";
import Noteitem from "./Noteitem";

const Notes = (props) => {
    const context = useContext(noteContext);
    const navigate = useNavigate();
    const {notes, getNotes, editNote} = context;
    useEffect(() => {
      if(localStorage.getItem('token')){
        getNotes()
      }else{
        navigate('/login')
      }
        // eslint-disable-next-line
    }, [])
   const [note, setNote] = useState({id:"", etitle:"", edescription:"", etag:""})
    // const [note, setNote] = useState("")
    const ref = useRef(null)
    const refClose = useRef(null)

    const updateNote = (currentNote)=>{
        ref.current.click();
        setNote({id:currentNote._id, etitle:currentNote.title, edescription:currentNote.description, etag:currentNote.tag});
       // props.showAlert("Notes updated successfully","success")
    }
    const handleClick =(e)=>{
      //console.log("updating the note : ",note);
      editNote(note.id,note.etitle,note.edescription,note.etag);
      refClose.current.click();
      props.showAlert("Notes Edited successfully","success");
  }

  const onChange = (e) =>{
      setNote({...note, [e.target.name]: e.target.value})
  }
    return (
        <>
        <AddNote showAlert={props.showAlert} />
      <button type="button" ref={ref} className="d-none btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
        Launch demo modal
      </button>
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Edit Note</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
              <form className="my-3">
                <div className="mb-3">
                  <label htmlFor="title" className="form-label">
                    Title
                  </label>
                  <input type="text" className="form-control" id="etitle" name="etitle" value={note.etitle} aria-describedby="emailHelp" onChange={onChange}  minLength={5} required/>
                </div>
                <div className="mb-3">
                  <label htmlFor="edesc" className="form-label">Description</label>
                  <input type="text" className="form-control" id="edescription" name="edescription" value={note.edescription} onChange={onChange} minLength={5} required/>
                </div>
                <div className="mb-3">
                  <label htmlFor="etag" className="form-label">Tag</label>
                  <input disabled={note.etitle.length<5 || note.edescription.length<5} type="text" className="form-control" id="etag" name="etag" value={note.etag} onChange={onChange}  minLength={5} required/>
                </div>
                {/* <button type="submit" className="btn btn-primary" onClick={handleClick}>
                  Add Note
                </button> */}
              </form>
              </div>
              <div className="modal-footer">
                <button ref={refClose} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary" onClick={handleClick} >Update Note</button>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-3">   
            <h2>Your Notes</h2>
            <div className="container">
              {notes.length === 0 && 'No notes to display....'}
            </div>
                {notes.length >= 0 && notes.map((note)=>{
                return <Noteitem key={note._id} updateNote={updateNote} note={note} showAlert={props.showAlert}/>;
                })}
        </div>
        </>
    )
}

export default Notes

