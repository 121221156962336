import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';


const Login = (props) => {
    const [credentials, setCredentials] = useState({email:"",password:""});
    //let history = useHistory();
    const navigate = useNavigate();
    const onChange = (e) =>{
        setCredentials({...credentials, [e.target.name]: e.target.value})
    }
    const handleSubmit = async (e)=>{ 
        e.preventDefault();
        const response = await fetch("http://localhost:5000/api/auth/login", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({email:credentials.email,password:credentials.password}) 
          });
          const json = await response.json();
          console.log(json);
          if(json.success){
            //set the auth token and Redirect
            localStorage.setItem('token', json.authToken);
            // history.push("/");
            props.showAlert("Logged in successfully","success")
            navigate('/');
          }else{
            props.showAlert("Invalid Credentials","danger")
              //alert("invalid credentials!!!!!");
          }
    }

    return (
        <div className="container mt-3">
          <h2 className='text-center'>Login to Continue to iNotebook</h2>
             <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email address</label>
                    <input type="email" className="form-control" value={credentials.email} onChange={onChange} id="email" name="email" aria-describedby="emailHelp" />
                </div>
                <div className="mb-3">
                    <label htmlFor="password" className="form-label">Password</label>
                    <input type="password" className="form-control" value={credentials.password} onChange={onChange} id="password" name="password" />
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
                </form>
        </div>
    )
}

export default Login
